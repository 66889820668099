// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../pages/home/Landing.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("../pages/NotFound.vue"),
  },
  {
    path: "/forbidden",
    component: () => import("../pages/Forbidden.vue"),
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("../pages/Dashboard.vue"),
  },
  {
    path: "/anggota",
    component: () => import("../pages/anggota/List.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/anggota/detail/:id",
    component: () => import("../pages/anggota/Detail.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/pengguna",
    component: () => import("../pages/pengguna/List.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/pengguna/Create.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/pengguna/Update.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/profil/",
    component: () => import("../pages/profil/Detail.vue"),
    meta: {
      requiredLevel: ["admin", "user"],
    },
  },
  {
    path: "/profil/",
    component: () => import("../pages/profil/Detail.vue"),
    meta: {
      requiredLevel: ["admin", "user"],
    },
  },
  {
    path: "/member/:id",
    component: () => import("../pages/home/Member.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("userData");
  const userAccess = isLoggedIn ? JSON.parse(isLoggedIn) : null;
  const userLevel = userAccess ? userAccess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (
    to.path !== "/" &&
    to.path !== "/login" &&
    !isLoggedIn &&
    to.path !== "/registrasi" &&
    to.path !== "/home" &&
    to.path !== "/payment" &&
    to.path !== "/report" &&
    to.path !== "/privacy-policy" &&
    !to.path.includes("/status/")
  ) {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && !requiredLevel.includes(userLevel)) {
    next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
  } else {
    next();
  }
});

export default router;
