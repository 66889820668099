<template>
  <!-- <img
    src="https://cdn.demoku.web.id/preview.png"
    alt="Gambar"
    class="preview"
  /> -->
  <UserBar v-if="!isLoginPage"></UserBar>
  <Sidebar v-if="!isLoginPage"></Sidebar>
  <router-view></router-view>
</template>

<script>
import UserBar from "./components/UserBar.vue";
import Sidebar from "./components/SideBar.vue";
export default {
  name: "App",
  components: {
    UserBar,
    Sidebar,
  },
  computed: {
    isLoginPage() {
      if (
        this.$route.path === "/" ||
        this.$route.path === "/home" ||
        this.$route.path === "/login" ||
        this.$route.path === "/member/" + this.$route.params.id ||
        this.$route.path === "/forbidden" ||
        this.$route.path === "/404" ||
        this.$route.path === "/cetak/" + this.$route.params.id
      ) {
        return true;
      } else {
        return false;
      }
    },
    layoutId() {
      return this.isLoginPage ? "layoutAuthentication" : "layoutSidenav";
    },
    contentId() {
      return this.isLoginPage
        ? "layoutAuthentication_content"
        : "layoutSidenav_content";
    },
  },
};
</script>
<style>
.preview {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 150px;
  z-index: 999;
}
</style>
